  <mat-sidenav-container style="display:flex; flex-direction: column; min-height: 100vh;">
  
    <!--NAV SECUNDARIO -->
    <!--<header-secundario></header-secundario>-->
  
    <!--NAVEGADOR PRINCIPAL-->
    @defer {
      <header></header>
    }@placeholder {
      <div style="height: 180px; width: 100%;"></div>
    }
    
  
    <!--<header-products></header-products>-->
  
  
    <!--RUTEO PAGINA GENERAL-->
    <div class="container-fluid" style="flex-grow: 1; min-height: 80vh;">
      <router-outlet></router-outlet>
    </div>
    @defer{
      <footer></footer>
    }
    
  </mat-sidenav-container>
