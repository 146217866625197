import { Routes } from '@angular/router';
import { ProductsService } from './_services/products.service';

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('@app/pages/home/home.routes').then(r => r.HomeRoutes)
  },
  {
    path: "detail",
    loadChildren: () => import('@app/pages/products/products.routes').then(r => r.ProductsRoutes)
  },
  {
    path: "search-results",
    loadChildren: () => import('@app/pages/search-results/search-results.routes').then(r => r.SearchResultsRoutes)
  },
  {
    path: "about-us",
    loadChildren: () => import('@app/pages/about-us/about-us.routes').then(r => r.AboutRoutes)
  },
  {
    path: "inspiration",
    loadChildren: () => import('@app/pages/inspiration/inspiration.routes').then(r => r.InspirationRoutes)
  },
  {
    path: "inspiration/:titulo",
    loadComponent: ()=> import('@app/pages/inspiration/inspiration-detail/inspiration-detail.component').then(c=>c.InspirationDetailComponent)
  },
  {
    path: "news",
    loadChildren: () => import('@app/pages/news/news.routes').then(r => r.NewsRoutes)
  },
  {
    path: "news/:title",
    loadComponent: () => import('@app/pages/news/new-detail/new-detail.component').then(c => c.NewDetailComponent)
  },
  {
    path: "our-statement",
    loadChildren: () => import('@app/pages/our-manifesto/our-manifesto.routes').then(r => r.OurManifestoRoutes)
  },
  {
    path: "products",
    loadChildren: () => import('@app/pages/products/products.routes').then(r => r.ProductsRoutes),
    providers:[ProductsService]
  },
  {
    path: "products/:brandName/:slug",
    loadChildren: () => import('@app/pages/products/products.routes').then(r => r.ProductsRoutes)
  },
  {
    path: "showroom",
    loadChildren: () => import('@app/pages/showroom/showroom.routes').then(r => r.ShowroomRoutes)
  },
  {
    path:'showroom/:showroom',
    loadComponent: ()=> import('src/app/pages/showroom/showroom-detail/showroom-detail.component').then(c=>c.ShowroomDetailComponent)
  },
  {
    path: "terms-conditions",
    loadChildren: () => import('@app/pages/terms-conditions/terms-conditions.routes').then(r => r.TermsConditionsRoutes),
    title:'Anima Domus - Terms and Conditions'
  },
  {
    path: "privacy-policy",
    loadChildren: () => import('@app/pages/privacy-policy/privacy-policy.routes').then(r => r.PrivacyPolicyRoutes),
    title: 'Anima Domus - Privacy Policy'
  },
  {
    path: "trade-program",
    loadChildren: () => import('@app/pages/trade-program/trade-program.routes').then(r => r.TradeProgramRoutes),
    title:'Anima Domus - Trade Program'
  },
  {
    path: "virtual-showroom",
    loadChildren: () => import('@app/pages/virtual-showroom/virtual-showroom.routes').then(r => r.VirtualShowroomRoutes),
    title:'Anima Domus - Virtual Showroom'
  },
  {
    path: "all-brands",
    loadChildren: () => import('@app/pages/brands/brands.routes').then(r => r.BrandsRoutes),
    title:'Anima Domus - All Brands'
  },
  {
    path: "contact-us",
    redirectTo:"contact"
  },
  {
    path: "contact",
    loadChildren: () => import('@app/pages/contact-us/contact-us.routes').then(r => r.ContactUsRoutes),
    title:'Anima Domus - Contact'
  },
  {
    path: "inquiry",
    loadChildren: () => import('@app/pages/inquiry/inquiry.routes').then(r => r.InquiryRoutes)
  },
  {
    path: "cart",
    loadChildren: () => import('@app/pages/cart/cart.routes').then(r => r.CartRoutes)
  },
  {
    path: "checkout",
    loadChildren: () => import('@app/pages/checkout/checkout.routes').then(r=>r.CheckoutRoutes)
  },
  {
    path: "account",
    loadChildren: () => import('@app/pages/account/account.routes').then(r => r.AccountRoutes)
  },
  {
    path: "success",
    loadChildren: () => import('@app/pages/success/success.routes').then(r => r.SuccessRoutes)
  },
  {
    path: "notfound",
    loadComponent: () => import('@app/theme/error/error.component').then(c => c.ErrorComponent)
  },
  {
    path: "**",
    redirectTo: '/notfound'
  }
];