import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { NoPreloading, provideRouter, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './_helpers';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideClientHydration(withHttpTransferCacheOptions({includePostRequests:false})),
    importProvidersFrom(MatSidenavModule),
        {
            provide: 'googleTagManagerId',
            useValue: 'GTM-5NJ4QT4'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                if (!config.loaderParams?.bcImage) {
                    let server = 'https://storage.googleapis.com/bucket-cms-ad/';
                    let size = '';
                    let imagePath = '';
                    let imgType = '.jpg';
                    if (config.loaderParams && config.loaderParams.type) {
                        imgType = config.loaderParams.type;
                    }
                    switch (config.width) {
                        case 250:
                            size = 'thumbnail_';
                            break;
                        case 500:
                            size = 'small_';
                            break;
                        case 750:
                            size = 'medium_';
                            break;
                        case 1000:
                            size = 'large_';
                            break;
                    }
                    if (!size.length)
                        size = 'medium_';
                    imagePath = size + config.src + '/';
                    return server + imagePath + size + config.src + imgType;
                }
                else {
                    return config.src;
                }
            }
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
  ]
};