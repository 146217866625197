import { ChangeDetectorRef, Component, HostListener, Inject, Optional, PLATFORM_ID, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { REQUEST } from '../express.tokens';
import { isPlatformServer } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [MatSidenavModule, RouterOutlet,HeaderComponent,FooterComponent]
})
export class AppComponent {
  
  public isBrowser:boolean
  public isLoading:boolean = true
  //@ViewChild('footer_container',{read:ViewContainerRef}) footerContainer: ViewContainerRef
  //@ViewChild('header_container',{read:ViewContainerRef}) headerContainer: ViewContainerRef

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private title: Title,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(REQUEST) protected request,
    ) {
    //Titulo pagina
    this.title.setTitle("Anima Domus")

    let domain
    if(request && isPlatformServer(platformId)){
      domain = `${request.protocol}://${request.get('host')}/`
      this.isBrowser = false
    }else{
      domain = '../../../'
      this.isBrowser = true
    }

    this.iconRegistry.addSvgIcon('add', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/add.svg'))
    this.iconRegistry.addSvgIcon('add-light', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/add-light.svg'))
    this.iconRegistry.addSvgIcon('attached', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Attached.svg'))
    this.iconRegistry.addSvgIcon('bag', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/bag.svg'))
    this.iconRegistry.addSvgIcon('cross', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Cross.svg'))
    this.iconRegistry.addSvgIcon('dropdown', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Dropdown.svg'))
    this.iconRegistry.addSvgIcon('expand', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/expand.svg'))
    this.iconRegistry.addSvgIcon('hamburger', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Hamburger.svg'))
    this.iconRegistry.addSvgIcon('inquiry', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Inquiry.svg'))
    this.iconRegistry.addSvgIcon('menos', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/menos.svg'))
    this.iconRegistry.addSvgIcon('nabBarMenu', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/navBarMenu.svg'))
    this.iconRegistry.addSvgIcon('search', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/Search.svg'))
    this.iconRegistry.addSvgIcon('undo', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/undo.svg'))
    this.iconRegistry.addSvgIcon('google', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/google.svg'))
    this.iconRegistry.addSvgIcon('logo', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/logo.svg'))
    this.iconRegistry.addSvgIcon('logo-sintesis', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/logo-sintesis.svg'))
    this.iconRegistry.addSvgIcon('logo-footer', this.sanitizer.bypassSecurityTrustResourceUrl(domain+'assets/icons/logo-footer.svg'))

  }

  

  ngAfterViewInit(): void {
    if(!isPlatformServer(this.platformId)){
      //document.getElementById('preloader').remove()  
      const script = document.createElement('script');
      script.src = 'https://cdn.weglot.com/weglot.min.js';
      script.async = true;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
      //agrego widget de accesibilidad de userway
      var s = document.createElement("script");s.setAttribute("data-account","z9of2Zn9J9");s.setAttribute("src","https://cdn.userway.org/widget.js");document.body.appendChild(s);
    }
    //setTimeout(()=>this.loadFooter(),4000)
    //this.loadHeader()
    this.isLoading = false

    
  }

  ngOnInit() {
    //this.loadHeader()
    
  }
  
  /*async loadFooter(){
      const { FooterComponent } = await import('src/app/core/footer/footer.component')
      this.footerContainer.createComponent(FooterComponent)
  }*/

  /*async loadHeader(){
    const {HeaderComponent} = await import('src/app/core/header/header.component')
    this.headerContainer.createComponent(HeaderComponent)
  }*/

}
